import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

export default ({ project, onDismiss }) => {
	return (
		<View>
			<ProjectView src={project} width='100%' height='100%' />
		</View>
	);
};

const View = styled(motion.div)`
	position: fixed;
	left: 0;
	top: 0;
	width: 90vw;
	height: 90vh;
	background-color: black;

	z-index: 1;
`;

const ProjectView = styled.iframe`
	margin: auto;
	width: 100%;
	height: 100%;
`;
