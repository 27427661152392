import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import useSWR, { SWRConfig } from 'swr';
import './index.css';

import * as c from './components';

const fetcher = (url) => fetch(url).then((r) => r.json());

export default () => {
	const { data } = useSWR(`${process.env.PUBLIC_URL}/media/ied.json`, fetcher);
	const [students, setStudents] = useState(null);
	// const [project, setProject] = useState(null);

	useEffect(() => {
		if (data) {
			setStudents([...data]);
		}
	}, [data]);

	const handleSelect = (link) => {
		const win = window.open(link, '_blank');
		win.focus();
	};

	// const handleSelect = (link) => {
	// 	setProject(link);
	// };

	// const handleDismiss = () => {
	// 	setProject(null);
	// };

	return (
		<SWRConfig
			value={{
				refreshInterval: 3000,
				fetcher,
			}}>
			<View>
				<c.Slideshow />
				<c.Intro />
				<c.Students students={students} onSelect={handleSelect} />

				{/* <AnimatePresence>{project && <c.Project project={project} onDismiss={handleDismiss} />}</AnimatePresence> */}
			</View>
		</SWRConfig>
	);
};

const View = styled(motion.div)`
	width: 100vw;
	height: 100vh;
`;
