import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import shuffle from 'lodash/shuffle';

export default () => {
	const cache = useRef(null);
	const timeoutRef = useRef(0);
	const indexRef = useRef(0);
	const [slide, setSlide] = useState(null);
	const [slides, setSlides] = useState(null);

	useEffect(() => {
		const _slides = [];
		const _slides_names = [...Array(12).keys()];
		const _slides_shuffled = shuffle(_slides_names);
		_slides_shuffled.map((slide) => _slides.push({ src: `${process.env.PUBLIC_URL}/media/background/bkg-${`${slide + 1}`}.jpg` }));
		setSlides([..._slides]);
	}, []);

	useEffect(() => {
		if (slides) {
			next(0);
		}
	}, [slides]);

	const next = (delay = 5) => {
		if (slides) {
			indexRef.current += 1;
			if (indexRef.current >= slides.length) {
				indexRef.current = 0;
			}
			const _next = slides[indexRef.current];

			setTimeout(() => {
				cache.current = new Image();
				cache.current.src = _next.src;
				cache.current.onload = () => {
					console.log('nex', _next.src);
					setSlide(_next.src);
				};
			}, delay * 1000);
		}
	};

	return (
		<View>
			<AnimatePresence>{slide && <Slide key={slide} src={slide} onLoad={() => next()} {...a} />}</AnimatePresence>
			<Dimmer />
		</View>
	);
};

const View = styled(motion.div)`
	position: fixed;
	width: 100vw;
	height: 100vh;
`;

const Slide = styled(motion.img)`
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

const Dimmer = styled(motion.div)`
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	background-color: rgba(0, 0, 0, 0.2);
`;

const a = {
	variants: {
		enter: { opacity: 1, transition: { delay: 2 } },
		exit: { opacity: 0, transition: { delay: 20 } },
	},
	initial: `exit`,
	animate: `enter`,
	exit: `exit`,
};
