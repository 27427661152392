import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

export default () => {
	return (
		<View>
			<h1>JANELAS ABERTAS</h1>
			<p>
				A pandemia impôs que a realidade da maioria da população, nos últimos meses, fosse habitar isoladamente espaços fechados. O único contato com
				o mundo foi através de janelas, sejam elas físicas ou virtuais. De uma janela aberta na parede, em busca de uma fresta de sol, a uma janela
				aberta no computador, em busca de uma fresta de afeto, de um familiar ou amigo distante. O trabalho de cada aluno da turma do Master
				Professional em Design de Entretenimento do IED-Rio, no módulo de Interface e Interatividade, teve como proposta responder à seguinte
				pergunta: o que você vê da sua janela?
				<p>
					Quer aproveitar essa exposição virtual de forma mais completa? Libere o acesso à sua câmera e divirta-se. Ah, e se entrar pelo computador, a
					experiência vai ser ainda mais imersiva.
				</p>
			</p>
			<p>
				<strong>
					IED-Rio | Design de entretenimento <br />
					2020.2
				</strong>
			</p>
		</View>
	);
};

const View = styled(motion.div)`
	position: fixed;
	left: 5rem;
	bottom: 5rem;
	width: 60rem;
	min-height: 27rem;
	background-color: rgba(0, 0, 0, 0.7);
	padding: 3.125rem;

	h1 {
		font-family: 'Montserrat', sans-serif;
		font-size: 2.5rem;
		color: white;
	}

	p {
		margin-top: 3rem;
		font-size: 1.5rem;
		line-height: 1.5;
		color: white;
	}

	@media (max-width: 480px) {
		box-sizing: border-box;
		top: 70vh;
		left: 0rem;
		padding: 1rem;
		position: absolute;
		width: 100vw;
		min-height: 100vh;
		background-color: rgba(0, 0, 0, 0.7);
		padding: 3.125rem;

		h1 {
			font-family: 'Montserrat', sans-serif;
			font-size: 8vw;
			color: white;
		}

		p {
			margin-top: 3rem;
			font-size: 4.16vw;
			line-height: 1.5;
			color: white;
		}
	}
`;
