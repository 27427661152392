import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Student } from '.';

export default ({ students, onSelect }) => {
	return (
		<View>
			{students?.map((student, index) => (
				<Student key={index} index={index} {...student} onSelect={onSelect} />
			))}
		</View>
	);
};

const View = styled(motion.div)`
	position: relative;
	display: flex;
	top: 100vh;
	width: 100vw;
	min-height: 100vh;
	padding: 5rem 0 5rem 16rem;
	background-color: #333;
	z-index: 1;

	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;

	@media (max-width: 480px) {
		position: absolute;
		top: 170vh;
		justify-content: center;
		padding: 2rem 0 2rem 0rem;
	}
`;
