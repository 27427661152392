import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

export default ({ index, title, description, author, link, onSelect }) => {
	return (
		<View onTap={() => onSelect(link)} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
			<img src={`${process.env.PUBLIC_URL}/media/thumbs/thb-${index + 1}.jpg`} />
			<h2>{title}</h2>
			<p className={`desc`}>{description}</p>
			<p className={`auth`}>{author}</p>
		</View>
	);
};

const View = styled(motion.div)`
	flex: 0 0 18.75rem;
	min-height: 31rem;
	margin: 1.25rem;
	background-color: black;

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0.9375rem;

	color: white;

	img {
		flex: 0 0 9.875rem;
		width: 17.5rem;
		height: 9.875rem;
		background-color: grey;
	}

	h2 {
		font-family: 'Montserrat', sans-serif;
		margin-top: 10px;
		flex: 0 0;
	}

	p.desc {
		margin-top: 10px;
		line-height: 1.5;
		flex: 1 0;
	}

	p.auth {
		font-family: 'Montserrat', sans-serif;
		flex: 0 0;
	}
`;
