import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Application from './application';
import { render } from 'react-snapshot';

// ReactDOM.
render(
	<React.StrictMode>
		<Application />
	</React.StrictMode>,
	document.getElementById('root'),
);
